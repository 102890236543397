import React from 'react';

// utils
import { useThemeName } from 'utils/hooks/use-theme';

// types
import type { TextBoxButtonProps } from './TextBoxButton.types';

// styles
import { StyledTextBoxButton } from './TextBoxButton.styles';

function TextBoxButton({
  color,
  button,
  className,
}: Readonly<TextBoxButtonProps> & { className?: string }) {
  const themeName = useThemeName();

  if (!button) return null;

  const _button = {
    ...button,
    showArrow: false,
  };

  return (
    <StyledTextBoxButton link={_button} themeName={themeName} className={className} color={color}>
      {_button.text}
    </StyledTextBoxButton>
  );
}

export { TextBoxButton };
