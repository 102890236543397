// types
import type { LoopType, SliderTextColors } from '../../ContentElements/Slider';

type TextBoxPositionType = 'top' | 'bottom';

export type VideoPlayerProps = {
  cssID: string;
  color: SliderTextColors;
  fallbackImg: string;
  inView: boolean;
  loopType: LoopType;
  video: VideoProp;
  animation?: boolean;
  button?: Link;
  hasPreviewId?: boolean;
  heading?: string;
  playerID?: string;
  playerType?: VideoPlayerType;
  position?: TextBoxPositionType;
  subheading?: string;
  title?: string;
  isHeroElement?: boolean;
};

export const VIDEO_PLAYER_STATUS = {
  LOADING: 'LOADING',
  DONE: 'DONE',
  ERROR: 'ERROR',
} as const;

type VideoPlayerValues<T> = T[keyof T];
export type VideoPlayerStatus = VideoPlayerValues<typeof VIDEO_PLAYER_STATUS>;

export const VIDEO_PLAYER_TYPE = {
  FOREGROUND: 'foreground',
  BACKGROUND: 'background',
} as const;

export type VideoPlayerType = VideoPlayerValues<typeof VIDEO_PLAYER_TYPE>;
