// types
import type { SliderItemProps } from '.';

// utils
import { isEmpty } from 'utils/is-empty';
import { ParentContextType } from 'scenes/parent-provider';

function whoHasBackgroundVideo(item: SliderItemProps) {
  return (
    !isEmpty(item.video) &&
    !isEmpty(item.video.videoId) &&
    (item.heroType === 'bgvideo' || item.videoType === 'bgvideo')
  );
}

export function getBgVideoSlide(items: SliderItemProps[]): SliderItemProps | undefined {
  if (!items || isEmpty(items)) {
    throw new Error('No video available!');
  }

  return items.find(whoHasBackgroundVideo);
}

export function getHeadlineTag(sliderContext: ParentContextType) {
  return sliderContext.parent === 'page' && sliderContext.pageType !== 'homepage' ? 'span' : 'h2';
}
