import { CmsLink } from 'components/Link/CmsLink';
import styled, { css } from 'styled-components';
import type { DefaultTheme } from 'styled-components';

// types
import type { ThemeNameProps } from 'themes/theme.types';
import type { TextBoxButtonProps } from './TextBoxButton.types';

export const StyledTextBoxButton = styled(CmsLink)<
  {
    themeName: ThemeNameProps;
  } & Pick<TextBoxButtonProps, 'color'>
>((props) => {
  const { color, theme, themeName } = props;

  return css`
    &&& {
      ${() =>
        ['NORD', 'KOLO', 'TWYFORD'].includes(themeName) &&
        getBasicStylingForNordKoloTwyford({ color, theme, themeName })};

      ${() => getElementStateStyles({ color, theme })};
    }
  `;
});

const getBasicStylingForNordKoloTwyford = ({
  color,
  theme,
  themeName,
}: {
  themeName: ThemeNameProps;
  theme: DefaultTheme;
} & Pick<TextBoxButtonProps, 'color'>) => {
  const borderColor = color === 'black' ? theme.colors.black : theme.colors.white;
  return css`
    border: 1px solid ${borderColor};

    font-size: ${theme.fontSizes[2]};
    line-height: ${theme.lineHeights[1]};
    font-weight: ${theme.fontWeights.regular};
    letter-spacing: ${theme.letterSpacings.normal};

    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: ${theme.fontWeights.regular};

    background-color: transparent;

    font-family: ${theme.fontTokens.defaultFont};

    color: ${() => (color === 'black' ? theme.colors.black : theme.colors.white)};
    box-sizing: border-box;
    white-space: nowrap;

    font-size: ${theme.fontSizes[2]};
    line-height: ${theme.lineHeights[1]};
    height: ${theme.sizes['10']};

    padding: var(--spacing-xxxxs) var(--spacing-s);
  `;
};

const getElementStateStyles = ({
  color,
  theme,
}: {
  theme: DefaultTheme;
} & Pick<TextBoxButtonProps, 'color'>) => {
  return css`
    &:hover {
      background-color: ${() => (color === 'black' ? 'var(--black-08)' : 'var(--white-07)')};
      color: ${theme.colors.black};
      border: 1px solid ${theme.colors.black};

      &&& span {
        border-bottom: unset;
      }
    }

    &:active {
      user-select: none;
      color: ${() => (color === 'black' ? theme.colors.white : theme.colors.black)};
      background-color: ${() => (color === 'black' ? 'var(--gdds_cl15)' : theme.colors.white)};
      border: 1px solid var(--black-00);
    }

    &:focus-visible {
      border: 1px solid ${theme.colors.black};
    }
  `;
};
