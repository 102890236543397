import React from 'react';

// components
import { TextBoxButton } from '../TextBoxButton';

// styles
import { StyledContentBox } from './ContentBox.styles';

// types
import type { ContentBoxProps } from './ContentBox.types';

// utils
import { exhaustiveGuard } from 'utils/exhaustiveGuard';
import { useThemeName } from 'utils/hooks/use-theme';

function ContentBox(props: ContentBoxProps) {
  const { heading, subheading } = props;

  const themeName = useThemeName();

  if (!heading && !subheading) return null;

  switch (themeName) {
    case 'DEFAULT':
      return (
        <StyledContentBox.Default {...props} className="content-box content-box--xy">
          <Content {...props} />
        </StyledContentBox.Default>
      );
    case 'GDDS':
      return (
        <StyledContentBox.Gdds {...props} className="content-box content-box--group">
          <Content {...props} />
        </StyledContentBox.Gdds>
      );
    case 'NORD':
      return (
        <StyledContentBox.Nord {...props} className="content-box content-box--nord">
          <Content {...props} />
        </StyledContentBox.Nord>
      );
    case 'KOLO':
      return (
        <StyledContentBox.Kolo {...props} className="content-box content-box--kolo">
          <Content {...props} />
        </StyledContentBox.Kolo>
      );
    case 'TWYFORD':
      return (
        <StyledContentBox.Twyford {...props} className="content-box content-box--twyford">
          <Content {...props} />
        </StyledContentBox.Twyford>
      );
    default:
      return exhaustiveGuard(themeName);
  }
}

function Content(props: ContentBoxProps) {
  const { button, color, hasPreviewId, heading, subheading } = props;
  return (
    <>
      <h1>
        {heading && (
          <span
            className="content-box__heading"
            data-preview-id={hasPreviewId ? '#st_headline' : undefined}
          >
            {heading}
          </span>
        )}
        {subheading && (
          <span
            className="content-box__subheading"
            data-preview-id={hasPreviewId ? '#st_subline' : undefined}
          >
            {subheading}
          </span>
        )}
      </h1>

      {button && <TextBoxButton color={color} button={button} className="content-box__button" />}
    </>
  );
}

export { ContentBox };
